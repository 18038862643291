import React, { Component } from "react";
import styled from 'styled-components';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
`;

const StyledContainer = styled.div`
  display: contents;
  width: 90%;
  align-items: center;
  justify-content: center;
`;

class Thanks extends Component {
  render() {

    return (
      <Wrapper>
        <StyledContainer>
          <h2>
          Obrigado pela sua mensagem!!
          </h2>
          <h3>
          entraremos em contato em breve.
          </h3>
        </StyledContainer>
      </Wrapper>
    );
  }
}

export default Thanks;
